/* @media (max-width: 1023px) {
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
} */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
}


/* nomal */
body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
}

.bg-blue {
  background-color: blue;
}

.bg-gray {
  background: #f5f6f9;
;
}

/* custom */
:root {
  --header-width: 1400px;
  --header-height: 85px;
  --header-mobile-height: 50px;
  --detail-width: 1200px;
  --detail-right-width: 350px;
}

@media (max-width: 1023px) {
    :root {
        --header-height: 60px;
    }
}

:root {

  --page-width: 1200px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
}

.block  {
  display: block !important;
}

.none {
  display: none !important;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline !important;
}

.visible {
  visibility: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

.max-h100vh {
  max-height: 100vh !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
    font-weight: bold !important;
}

/* ======================================================== */
/* Header  */

.header {
  height: var(--header-height);
  /* 평상시에 height를 초과한 서브메뉴가 안보이게 hidden */
  position: fixed;
  width: 100%;
  z-index: 500;
  background-color: #fff;
}

.header-changed {
  box-shadow: 0 1px 2px #cfcfcf,
              0 0px 3px #888888;
}

.header__con {
  height: 85px;
  max-width: var(--header-width);
  width: 100%;
  margin: 0 auto;
  padding: var(--page-padding-pc);
  display: flex;
  justify-content: space-between;
  color: #111;
  z-index: 300 !important;
}

.header__logo-con {
  height: 100%;
  display: flex;
}

.header__logo img {
  width: 120px;
}


.header__logo-con > li {
  height: 100%; 
  font-size: 23px;
  font-weight: 700;
    letter-spacing: -1px;
  line-height: 28px;

}

.header__logo-con > li > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.header__category-con {
  height: 100%;
  display: flex;
  align-items: center;

}

.header__category-con > li {
  /* height: 100%; */
  /* width: 90px; */
  padding: 0 20px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
}

.header__category-con > li > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__hamburger {
  display: none;
}

.header__contact-btn {
    display: inline-block;
    width: 100px !important;
    height: 48px;
    /* color: #fff; */
    /* background-color: #0173b1; */
    /* background-color: #111; */
    /* border-radius: 20px; */
}

.header__lang-btn {
  display: flex;
  align-items: center;
}

.header__lang-btn-con {
  position: relative;
  padding: 8px 16px;
  text-align: center;
  color: #383433;
  border: 1px solid #aaa8a8;
  /* background-color: #2a2a2a;
  color: #fff;
  box-sizing: border-box; */

  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.header__lang-btn-con > span {
  margin-left: 20px;
  font-size: 8px;
  font-weight: 600;
  vertical-align: top;
}

.header__lang-btn-con > ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
}

.header__lang-btn-con > ul li {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
  color: #fff;
}

.header__lang-btn-con:hover > ul {
  display: block;
}



.header-mo {
    display: none;
}

.header-block {
    height: 85px;
}
  

@media (max-width: 1023px) {
    .header-block {
        height: var(--header-height);
    }

  .header-unclick {
    height: 60px !important;
  }

  /* .header-click {
    height: 90% !important;
  } */

  .header__con {
    height: 60px;
    display: block;
    padding: var(--page-padding-mo);
  }

  .header__logo-con {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .header__logo img {
    margin-left: -8px;
    width: 100px;
  }
  

  .header__logo > a {
    font-size: 20px;
  }

  .header__hamburger {
    display: block;
  }

  .header__hamburger img {
    width: 24px;
  }


  .header__category-con {
    display: none;
  }

  .header-mo {
    display: block;
    position: fixed;
    width: 80%;
    height: 100vh;
    left: -110%;
    top: 0;
    z-index: 999;
    transition: left .4s ease-in;
    background-color: #f1f0f0;
  }

  .left-0 {
    left: 0;
    }

    .header-mo__category-con {
        margin-top: 50px;
    }

    .header-mo__category-con li > a {
        width: 100%;
        border-bottom: 1px solid #d5d5d5;
    }

    .header-mo__category-con li > a {
        display: inline-block;
        padding: 18px 30px;
        font-size: 14px;
        font-weight: 400;
    }

    .bg-gray {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 888;
        background-color: rgba(0, 0, 0, 0.8);
    }

  .header .header__lang-btn {
    display: none;
  }

  .header__lang-btn {
    justify-content: flex-end;
    margin-top: 30px;
    margin-right: 30px;
  }
}

/* ======================================================== */

.intro-hero {
    display: flex;
    height: calc(100vh - var(--header-height));
}

.intro-hero__txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}

.intro-hero__txt-con {
    margin-top: -40px;
    max-width: 550px;
    width: 100%;
    padding: 0 20px;
}

.intro-hero__txt-title {
    font-size: 45px;
    line-height: 55px;
    letter-spacing: -0.5px;
    font-weight: 600;
}

.intro-hero__txt-sub {
    margin-top: 40px;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.5px;
    font-weight: 300;
    color: #7e7e7e;
    word-break: keep-all;
}

.intro-hero__btn-con {
    margin-top: 60px;
    display: flex;
}

.intro-hero__btn-con a {
    display: inline-block;
    padding: 20px 40px;
    /* background-color: red; */
    /* border: 1px solid #111; */
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.5px;
    color: #fff;

    border-radius: 30px;
    /* background-color: #0173b1; */
    background-color: #111;
    cursor: pointer;
}

.intro-hero__btn-con a:last-child {
    margin-left: 20px;
    border: 1px solid #afafaf;
    background-color: #fff;
    color: #111;
}

.intro-hero__img-wrap {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: relative;
    
}

.progress-bar-wrap {
  position: absolute;
  left: 50%;
  bottom: 10%;
  width: 300px;
  height: 3px;
  border-radius: 20px;

  transform: translateX(-50%);
  background-color: #afafaf;
  z-index: 999999;
}

.progress-bar-wrap > div {
  width: 0%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
}

.intro-hero__slick {
  width: 100%;
  height: 100%;
  top: -15%;
}

.intro-hero__slick img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media (max-width: 1023px) {
    .intro-hero {
        flex-direction: column;
    }

    .intro-hero__txt {
        width: 100%;
        height: 32vh;
    }

    .intro-hero__txt-con {
        margin-top: 0px;
        text-align: center;
    }

    .intro-hero__txt-title {
        margin-top: -15px;
        font-size: 28px;
        line-height: 35px;
    }

    .intro-hero__txt-sub {
        margin-top: 30px;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: -1px;
    }

    .intro-hero__btn-con {
      display: none;
        margin-top: 25px;
        justify-content: center;
    }
    
    .intro-hero__btn-con a {
        padding: 12px 20px;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: -.5px;
    
        border-radius: 25px;
    }
    
    .intro-hero__btn-con a:last-child {
        margin-left: 15px;
    }

    .intro-hero__slick {
      top: 0;
    }

    .intro-hero__img-wrap {
        width: 100%;
        height: calc(100vh - 32vh - var(--header-height));
    }


    .progress-bar-wrap {
      bottom: 10%;
      width: 200px;
      height: 2px;
    }
    
}

/* ======================================================== */

.intro-brand {
  margin: 0 auto;
  margin-top: 120px;
  max-width: var(--page-width);
  width: 100%;
  padding: var(--page-padding-pc);
  word-break: keep-all;
  /* height: calc((100vh - var(--header-height))); */
}

.intro-brand__title {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: -.5px;
}

.intro-brand__img-wrap {
  margin-top: 30px;
}

.intro-brand__img-wrap > img {
  width: 100%;
  aspect-ratio: 16/7;
  object-fit: cover;
  object-position: 0% 0%;
  border-radius: 25px;
  box-shadow: 2px 2px 4px #9e9e9e;
}

.intro-brand__txt-con {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.intro-brand__txt-item {
  width: 45%;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: -.5px;
  word-break: keep-all;  
}

/* sub */
.intro-brand__txt-item > div:last-child {
  margin-top: 15px;
  font-size: 16px ;
  font-weight: 300;
  line-height: 26px;
  color: #6c6b6b;
}

.btn-more {
  display: none;
}

@media (max-width: 1023px) {
  .intro-brand {
    margin-top: 80px;
    padding: var(--page-padding-mo);
  }
 
  .intro-brand__title {
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -.5px;
  }

  .intro-brand__img-wrap {
    margin-top: 30px;
  }

  .intro-brand__img-wrap > img {
    aspect-ratio: 16/10;
    border-radius: 20px;
    box-shadow: 2px 2px 3px #9e9e9e;
  }

  .intro-brand__txt-con {
    margin-top: 0px;
    margin-bottom: 40px;
    /* width: 100%; */
    /* display: flex; */
    /* justify-content: space-between; */
    flex-direction: column;
    align-items: center;
  }
  
  .intro-brand__txt-item {
    margin-top: 30px;
    width: 90%;
    font-size: 22px;
    /* text-align: center; */
    /* font-weight: 500; */
    /* letter-spacing: -.5px; */
    /* word-break: keep-all;   */
  }
  
  /* sub */
  .intro-brand__txt-item > div:last-child {
    margin-top: 15px;
    font-size: 14px;
    /* font-weight: 300; */
    line-height: 24px;
    
    /* color: #6c6b6b; */
  }

  .btn-more {
    display: block;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-more > a {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 90%;
    height: 100%;
    font-size: 15px;
    font-weight: 500;
    background-color: #336cad;
    color: #fff;
    border-radius: 25px;
  }
}

/* ======================================================== */

.intro-service {
  margin: 0 auto;
  margin-top: 120px;
  max-width: var(--page-width);
  width: 100%;
  padding: var(--page-padding-pc);
  /* height: calc((100vh - var(--header-height))); */
}

.intro-service__txt-con > div {
  text-align: center;
  letter-spacing: -.5px;
}

.intro-service__txt-title {
  font-size: 40px;
  font-weight: 600;
}

.intro-service__txt-sub {
  margin-top: 10px;
  font-size: 20px;
  font-weight: 400;
  color: #575757;
}

.section__txt-line {
  width: 40px;
  height: 3px;
  background: #383433;
  margin: 40px auto;
  text-align: center;
}

.intro-service__grid-con {
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-left: -10px;
}

.intro-service__grid-item {
  margin: 20px 15px;
  width: calc( (100% - 3 * 30px) / 3 );
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #f1f7ff; */
  background-image: linear-gradient(to top, #f1f1f2 0%, #f9fcfd 100%);
  border-radius: 20px;
  box-shadow: 2px 2px 3px #afafaf;
  position: relative;
}


.intro-service__grid-item-icon {
  font-size: 20px;
  font-weight: 600;
  color: #5d5d5d;

  position: absolute;
  top: 20%;
  transition: .5s ease-in;
}

.intro-service__grid-item-icon > img {
  width: 55px;
  transition: .5s ease-in;
}

/* 호버 시 카드가 움직이는 기능... 필요하면 쓰기 */
/* .intro-service__grid-item:hover > .intro-service__grid-item-icon { */
.intro-service__grid-item > .intro-service__grid-item-icon {
  top: 7%;
}
/* 호버 시 카드가 움직이는 기능... 필요하면 쓰기 */
/* .intro-service__grid-item:hover > .intro-service__grid-item-icon > img { */
.intro-service__grid-item > .intro-service__grid-item-icon > img {
  width: 40px;
}

.intro-service__grid-item-title {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -.5px;

  position: absolute;
  top: 60%;
  transition: .5s ease-in;
}

/* 호버 시 카드가 움직이는 기능... 필요하면 쓰기 */
/* .intro-service__grid-item:hover > .intro-service__grid-item-title { */
.intro-service__grid-item > .intro-service__grid-item-title {
  font-size: 20px;
  top: 32%;
}

.intro-service__grid-item-sub {
  width: 80%;
  font-size: 14px;
  font-weight: 300;
  color: #3f3e3e;
  text-align: center;

  position: absolute;
  top: 52%;
  opacity: 0;
}

/* 호버 시 카드가 움직이는 기능... 필요하면 쓰기 */
/* .intro-service__grid-item:hover > .intro-service__grid-item-sub { */
.intro-service__grid-item > .intro-service__grid-item-sub {
  opacity: 1;
  transition: .5s .5s ease-in;
}

@media (max-width: 1023px) {
  .intro-service {
    margin-top: 80px;
    width: 100%;
    padding: var(--page-padding-mo);
  }

  .intro-service__grid-con {
    flex-direction: column;
    margin-top: -15px;
    margin-left: 0;
    margin-bottom: 30px;
  }

  .intro-service__grid-item {
    margin: 15px 0;
    width: 100%;
    height: 180px;    
    /* border-radius: 20px; */
  }

  .intro-service__grid-item:not(:nth-child(1), :nth-child(2), :nth-child(3)) {
    display: none;
  }

  .ourservice__grid-item {
    display: flex !important;
  }

  .intro-service__txt-title {
    font-size: 26px;
    /* font-weight: 600; */
  }
  
  .intro-service__txt-sub {
    margin-top: 8px;
    font-size: 18px;
  }
}

/* ======================================================== */

.intro-menu {
  margin: 0 auto;
  margin-top: 120px;
  max-width: var(--page-width);
  width: 100%;
  padding: var(--page-padding-pc);
}

.intro-menu__up-grid-con,
.intro-menu__down-grid-con {
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-left: -5px;
}

.intro-menu__bg-gray {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.intro-menu__up-grid-con > div {
  margin: 4px 4px;
  width: calc( (100% - 3 * 8px) / 3 );
  aspect-ratio: 1;
  position: relative;
  cursor: pointer;
}

.intro-menu__up-grid-con > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

.intro-menu__up-grid-con > div > span,
.intro-menu__down-grid-con > div > span {
  position: absolute;
  top: 10%;
  left: 10%;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  z-index: 3;
}

.intro-menu__down-grid-con > div {
  margin: 8px 4px;
  width: calc( (100% - 2 * 8px) / 2 );
  aspect-ratio: 5/3;
  position: relative;
  cursor: pointer;
}

.intro-menu__down-grid-con > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

@media (max-width: 1023px) {
  .intro-menu {
    margin-top: 80px;
    padding: 0;
  }
  .intro-menu__up-grid-con,
  .intro-menu__down-grid-con {
    flex-direction: column;
    margin-top: 0;
    margin-left: 0;
  }
  .intro-menu__up-grid-con > div,
  .intro-menu__down-grid-con > div {
    margin: 0;
    width: 100%;
    height: 220px;
  }
  .intro-menu__down-grid-con > div:last-child {
    display: none;
  }
  .intro-menu__up-grid-con > div > span,
  .intro-menu__down-grid-con > div > span {
    top: 7%;
    left: 7%;
    font-size: 22px;

  }
}

/* ======================================================== */

.intro-contact {
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 120px;
  max-width: var(--page-width);
  width: 100%;
  padding: var(--page-padding-pc);

  display: flex;
  justify-content: space-between;
}

.intro-contact__txt {
  width: 45%;
}

.intro-contact__txt-title {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
  letter-spacing: -.5px;
}

.intro-contact__txt-sub {
  margin-top: 30px;
}

.intro-contact__txt-sub > li {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #828282;
}

.intro-contact__btn-con {
  margin-top: 30px;
  display: flex;
}

.intro-contact__btn-con a {
  display: inline-block;
  padding: 15px 25px;
  /* background-color: red; */
  /* border: 1px solid #111; */
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -.5px;
  color: #fff;

  /* border-radius: 30px; */
  /* background-color: #0173b1; */
  background-color: #336cad;
  cursor: pointer;
}

.intro-contact__img-wrap {
  width: 45%;
}

.intro-contact__img-wrap > img {
  width: 100%;
  aspect-ratio: 16/10;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 2px 2px 3px #969696;
}

@media (max-width: 1023px) {
  .intro-contact {
    margin-top: 60px;
    margin-bottom: 60px;
    padding: var(--page-padding-mo);
  
    flex-direction: column;
  }

  .intro-contact__txt {
    width: 100%;
  }

  .intro-contact__txt-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
  }

  .intro-contact__txt-sub > li {
    margin-bottom: 13px;
    font-size: 16px;
    line-height: 24px;
  }

  .intro-contact__btn-con a {
    width: 100%;
    text-align: center;
  }

  .intro-contact__img-wrap {
    margin-top: 40px;
    width: 100%;
  }

  .intro-contact__img-wrap > img {
    aspect-ratio: 16/12;
  }
}

/* ========================================================= */

.detail-page__banner-con {
  position: relative;
  width: 100%;
  height: 250px;
}

.detail-page__banner-con > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
  font-weight: 600;
  letter-spacing: -.5px;
  color: #fff;
}

.detail-page__banner-con > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.detail-page__con {
  margin: 0 auto;
  max-width: var(--page-width);
  width: 100%;
  min-height: 50vh;
  padding: var(--page-padding-pc);  
}

.detail-page__txt-line {
  width: 60px;
  height: 3px;
  background: #7198ed;
  margin: 40px auto;
  text-align: center;
}

.detail-page__txt-con > div {
  text-align: center;
  letter-spacing: -1px;
}

.detail-page__txt-con > div:first-child {
  margin-bottom: 30px;
}

.detail-page__txt-con > div:last-child {
  margin-bottom: 70px;
}

.detail-page__txt-title {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
}

.detail-page__txt-sub {
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: -0.5px;
  color: #3f3f3f;
}

@media (max-width: 1023px) {
  .detail-page__txt-line {
    width: 50px;
    height: 2px;
    margin: 30px auto;
  }
  
  .detail-page__txt-con > div {
    margin-bottom: 50px;
  }
  
  .detail-page__txt-title {
    font-size: 25px;
    line-height: 25px;
  }

  .detail-page__con {
    padding: var(--page-padding-mo);  
  }
}


/* ========================================================= */
.introduction__content-con{
 margin-bottom: 120px;
}

.introduction__content-item {
  display: flex;
  justify-content: space-between;
}

.introduction__content-item:not(:first-child) {
  margin-top: 80px;
}

.introduction__content-txt-con {
  width: 40%;
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.introduction__left {
  margin-left: 100px;
}

.introduction__right {
  margin-right: 100px;
}


.introduction__content-txt-title {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
}

.introduction__content-txt-sub {
  margin-top: 40px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;
  letter-spacing: -1px;
  color: #333333;
  word-break: keep-all;
}

.introduction__content-item > img {
  width: 40%;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 2px 2px 3px #818080;
}

.introduction__img-mo {
  display: none;
}

@media (max-width: 1023px) {
  .introduction__content-con{
    margin-bottom: 60px;
   }

  .introduction__content-item {
    flex-direction: column;
  }

  .introduction__left {
    margin: 0;
  }
  
  .introduction__right {
    margin: 0;
  }

  .introduction__content-item:not(:first-child) {
    margin-top: 60px;
  }  

  .introduction__content-txt-con {
    width: 100%;
  }
  
  .introduction__content-txt-title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }
  
  .introduction__content-txt-sub {
    margin-top: 0px;
    font-size: 16px;
    /* font-weight: 300; */
    line-height: 26px;
    text-align: center;
  }
  

  .introduction__img-mo {
    display: block;
  }
  
  .introduction__img-pc {
    display: none;
  }

  .introduction__content-item > img{
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 2px #333;

  }
}

/* ========================================================= */

.detail-page__history-con {
  margin-top: -50px;
}

.detail-page__history-item {
  padding: 50px 0;
  display: flex;
  border-bottom: 1px solid #e1e0e0;
}

.detail-page__history-year {
  width: 200px;
  font-size: 40px;
  font-weight: 600;
  color: #0853bc;
}

.detail-page__history-content {
  font-size: 18px;
  line-height: 35px;
  font-weight: 300;
  letter-spacing: -.5px;
  color: #3d3d3d;
}

@media (max-width: 1023px) {

  .detail-page__history-con {
    margin-top: -30px;
  }
  
  .detail-page__history-item {
    padding: 30px 0;
    flex-direction: column;
  }

  .detail-page__history-year {
    width: 100px;
    font-size: 30px;
  }

  .detail-page__history-content {
    margin-top: 20px;
    font-size: 16px;
    line-height: 30px;
  }
}

/* ========================================================= */

.ourservice__con {
  text-align: center;
  margin-bottom: 100px;
}

.img-it {
  width: 80%;
  aspect-ratio: 16/8;
  object-fit: cover;
  margin-bottom: 30px;
  border-radius: 20px;
}

.ourservice__box-con {
  display: flex;
  flex-direction: column;
  margin: 0 -30px;
}

.ourservice__box-item {
  /* width: calc(100% - 240px); */
  width: 100%;
  margin: 30px;
  text-align: left;
  letter-spacing: -.5px;
}

/* 하단의 텍스트임 */
.ourservice__box-txt01 {
  padding-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
  color: #006aff;
}

.ourservice__box-txt02 {
  padding-bottom: 10px;
  font-size: 22px;
  font-weight: 500;
  color: #111;
}

.ourservice__box-txt03 {
  font-size: 18px;
  line-height: 35px;
  color: #707070;
}

@media (max-width: 1023px) {
  .ourservice {
      padding: 64px 0;
  }
  .ourservice__con {
    margin-bottom: 40px;
  }
  .ourservice__txt01 {
      font-size: 16px;
      padding-bottom: 12px;
  }
  .ourservice__txt02 {
      padding-bottom: 30px;
      font-size: 22px;
      line-height: 38px;
  }
  .ourservice__box-item {
      width: calc(33% - 60px);
      margin: 0 30px;
      text-align: left;
  }

  .ourservice__box-txt01 {
      padding-bottom: 5px;
      font-size: 25px;
  }
  
  .ourservice__box-txt02 {
      padding-bottom: 10px;
      font-size: 20px;
  }
  
  .ourservice__box-txt03 {
      font-size: 15px;
      line-height: 25px;
  }    
}
@media (max-width: 767px) {
  .img-it {
    width: 90%;
    aspect-ratio: 16/10;
    margin-bottom: 30px;
  }
  .ourservice__box-con {
      flex-direction: column;
      margin: 0;
      align-items: center;
  }
  .ourservice__box-item {
      width: 100%;
      max-width: 300px;
      margin: 0;
      padding-bottom: 30px;
  }
}

/* ========================================================= */

.portfolio__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);
} 

.portfolio__grid-con { 
  /* margin: 40px 0 40px -10px; */
  display: flex;
  flex-wrap: wrap;
}

.portfolio__grid-item {
  margin: 10px;
  width: calc( (100% - 3 * 20px) / 3 );
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
}

.portfolio__img-box {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* border-radius: 10px; */
}

.portfolio__img-box::after {
  content: '';
  display: block;
  padding-bottom: 75%;
}

@media (max-width: 1023px) {
  .portfolio__con {
    padding: var(--page-padding-tab);
  } 

  .portfolio__txt-sub {
    font-size: 18px;
  }

}
@media (max-width: 767px) {
  .portfolio__con {
    padding: var(--page-padding-mo);
  }

  .portfolio__grid-item {
    margin: 0;
    margin-bottom: 15px;
    width: calc( (100% - 2 * 5px) / 2 );
  }

  .portfolio__grid-item:nth-child(2n-1) {
    margin-right: 10px;
  } 
}
@media (max-width: 480px) {
}

/* ========================================================= */

.board__table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* table 내 말줄임 (...) 기능 (1/4) */
}

.board__thead > tr > th {
  text-align: left;
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
  color: #646464;
  border-bottom: 2px solid rgba(0,0,0,0.3);
}

/* 표 하단 선 */
.board__tbody > tr:last-child {
  border-bottom: 2px solid rgba(0,0,0,0.3);
}

.board__th01 {
  width: 20%;
}
.board__th02 {
  width: 100%;
}
.board__th03 {
  width: 15%;
}

.board__tbody {
  position: relative;
}


.board__tbody > tr {
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.board__tbody > tr > td {
  padding: 5px 0;
  font-size: 18px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: -.5px;

  /* table 내 말줄임 (...) 기능 (2~4/4) */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.board__tbody > tr > td:nth-child(2) {
  font-weight: 500;
}

.board__list-con {
  display: none;
}

.board__page-number {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.board__page-number > div {
  margin-top: 20px;
  margin-right: 10px;

  font-size: 15px;
  cursor: pointer;
}


@media (max-width:834px) {
  .board__table {
    display: none;
  }

  .board__list-con {
    display: block;
    position: relative;
  }

  .board__list-item {
    font-size: 16px;
  }

  .board__list01 {
    padding-top: 15px;
    font-weight: 400;
    border-top: 1px solid rgba(0,0,0,0.2);
    letter-spacing: -.5px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .board__list02 {
    padding-top: 5px;
    font-size: 15px;
    padding-bottom: 15px;
    color: #6d6b6b;
  }

  .board__list-item:nth-last-child(1) > .board__list02 {
    border-bottom: 1px solid rgba(0,0,0,0.2);
  }
}

/* ========================================================== */

.board-detail__board-name {
  padding-bottom: 25px;
  border-bottom: 2px solid #474747;
}

.board-detail__board-name > span {
  padding: 5px 7px;

  font-size: 15px;
  font-weight: 500;
  letter-spacing: -1px;
  text-align: left;
  color: #3468eb;

  border: 2px solid #1b3f94;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 2px #f5f8ff;
  cursor: pointer;
}


.board-detail__header-title{
  margin-top: 20px;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  color: #434242;
}

.board-detail__header-info {
  padding: 15px 0 30px 0;
  display: flex;
  justify-content: space-between;

  font-size: 15px;
  line-height: 15px;
  border-bottom: 1px solid #d3d1d1;
}

.board-detail__header-info > ul {
  display: flex;
}

.board-detail__header-info > ul > li:first-child {
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #afafaf;
}


.board-detail__content-con {
  padding: 30px 0;
  border-bottom: 1px solid #d3d1d1;
}

.board-detail__content-item > img {
  margin: 0 auto;
  margin-bottom: 30px;
  display: block;
  max-width: 600px;
  width: 100%;

}

.board-detail__content-item > pre {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  font-family: 'Noto Sans KR', sans-serif;

  /* pre 태그의 내용이 삐져나옴 방지 */
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.board-detail__content-item > pre img {
  max-width: 100%;
}

@media (max-width:834px) {

  .board-detail__board-name {
    padding-bottom: 20px;
  }
  
  .board-detail__board-name > span {
    padding: 5px 7px;
    font-size: 15px;
    border-radius: 8px;
  }
  
  .board-detail__header-title{
    margin-top: 15px;
    font-size: 20px;
  }

  .board-detail__header-info {
    padding: 15px 0 20px 0;
    font-size: 12px;
    line-height: 12px;
  }

  .board-detail__header-info > ul > li:first-child {
    padding-right: 10px;
    margin-right: 10px;
  }
  
  .board-detail__content-con {
    padding: 20px 0;
  }

  .board-detail__content-item {
    font-size: 15px;
  }
}

/* ========================================================= */

.footer {
  padding: 50px 0;

  /* custom */
  background: rgb(76, 76, 76);
  color: lightgray;
}

.footer__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);

  display: flex;
  position: relative;
}

.footer__logo {
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: white;

  font-weight: bold;
}

.footer__txt01 {
  padding-bottom: 10px;
  font-size: 13px;
  line-height: 24px;
}

.footer__txt02 {
  font-size: 16px;
}

.footer__law-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
}

@media (max-width: 1023px) {
  .footer {
      padding-bottom: 50px;
  }
  .footer__con {
      padding: var(--page-padding-tab);
      flex-direction: column;
  }
  .footer__logo {
      margin-bottom: 10px;
      justify-content: left;
      font-size: 24px;
  }
  .footer__txt01 {
      font-size: 12px;
      line-height: 20px;
  }
  
  .footer__txt02 {
      font-size: 14px;
  }    
  .footer__law-con {
      right: 50%;
      transform: translateX(50%);
      bottom: 30px;
  }
}
@media (max-width: 767px) {
  .footer__con {
      padding: var(--page-padding-mo);
  }
}
@media (max-width: 480px) {
}

  /* ========================================================= */

  .license__con {
    padding: var(--page-padding-pc);
    margin: 0 auto;
    width: 100%;
    max-width: var(--page-width);
  } 
  
  .license__grid-con { 
    margin: 100px 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .license__grid-item {
    margin: 30px 10px 30px 10px;
    width: calc( (100% - 2 * 20px) / 2 );
    display: flex;
    flex-direction: column;
    /* cursor: pointer; */
    position: relative;
  }
  
  .license__source-name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #428ef9;
    cursor: pointer;
  }

  .license__source-url {
    letter-spacing: -1px;
    opacity: 0.7;
    font-weight: 400;
    color: darkblue;
    text-decoration: underline;
  }

  .license__source-type {
    letter-spacing: -1px;
    opacity: 0.7;
  }
  
  
  .license__source-writer {
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 22px;
  }

  .license__txt-item {
    padding: 30px 0;
    border-top: 1px solid #afafaf;
  }

  .license__txt-title {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }

  .license__txt-sub {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
    color: #222222;
  }
  
  
  @media (max-width: 1023px) {
    .license__con {
      padding: var(--page-padding-tab);
    } 
  
    .license__txt-sub {
      font-size: 18px;
    }
  
  }
  @media (max-width: 767px) {
    .license__con {
      padding: var(--page-padding-mo);
    }
  
    .license__grid-item {
      margin: 15px 0px 15px 0px;
      margin-bottom: 15px;
      width: calc( (100% - 2 * 10px) / 2 );
    }
  
    .license__grid-item:nth-child(2n-1) {
      margin-right: 20px;
    }
  
    .license__source-name {
      font-size: 18px;
    }
  
    
    .license__source-type {
      font-size: 15px;
    }
  
    .license__source-writer {
      font-size: 15px;
      font-weight: 400;
    }
  
   
  }
  @media (max-width: 480px) {
  }
  
  